import BaseMethods from '../BaseMethods';
import {getData} from "@/api/v2/utils/DataHandler";

export default new (class extends BaseMethods {
    endpointRoute = '/api/v2/templates';

    async store(data) {
        return this.asFormData().post(this.endpointRoute, data).then(getData);
    }

    async update(id, data) {
        const url = this.endpointRoute + '/' + id;

        return this.asFormData().post(url, data).then(getData);
    }
})();
